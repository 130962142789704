import PageHeader from '../components/PageHeader';
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';

const Pricing = () => {
  const pricingPlans = {
    websites: [
      {
        title: 'Single Page HTML Website',
        price: '$500+',
        description: 'Perfect for small businesses starting their online presence',
        features: [
          'Responsive Design',
          'Contact Form',
          'SEO Optimization',
          'Custom Styling',
          '2 Rounds of Revisions',
          '1 Month Support'
        ],
        recommended: false
      },
      {
        title: 'Single Page React Website',
        price: '$1,200+',
        description: 'Dynamic website with modern interactivity',
        features: [
          'All HTML Features',
          'Interactive UI Components',
          'Smooth Animations',
          'Performance Optimization',
          '3 Rounds of Revisions',
          '3 Months Support'
        ],
        recommended: true
      },
      {
        title: 'Multi-Page React Website',
        price: '$3,000+',
        description: 'Complete web solution for growing businesses',
        features: [
          'All React Features',
          'Multiple Pages/Routes',
          'Custom Admin Dashboard',
          'API Integration',
          'Unlimited Revisions',
          '6 Months Support'
        ],
        recommended: false
      }
    ],
    analytics: [
      {
        title: 'Data Analysis & Reporting',
        price: '$500+',
        description: 'Basic data analysis and insights',
        features: [
          'Data Cleaning',
          'Exploratory Analysis',
          'Basic Visualizations',
          'PDF Report',
          'One-time Analysis',
          'Results Presentation'
        ],
        recommended: false
      },
      {
        title: 'Business Intelligence Dashboard',
        price: '$3,500+',
        description: 'Interactive dashboards for real-time insights',
        features: [
          'Custom Dashboard Design',
          'Real-time Data Integration',
          'Interactive Filters',
          'Automated Reports',
          'User Training',
          '3 Months Support'
        ],
        recommended: true
      },
      {
        title: 'ML & Predictive Analytics',
        price: '$5,000+',
        description: 'Advanced analytics with predictive modeling',
        features: [
          'Custom ML Models',
          'Predictive Analytics',
          'Model Deployment',
          'API Integration',
          'Performance Monitoring',
          '6 Months Support'
        ],
        recommended: false
      }
    ]
  };

  return (
    <div>
      <PageHeader
        title="Transparent Pricing"
        subtitle="Flexible Solutions for Every Business Need"
        backgroundImage="url('https://images.unsplash.com/photo-1554224155-8d04cb21cd6c?auto=format&fit=crop&w=1600&q=80')"
      />

      <div className="container mt-5">
        {/* Web Development Pricing */}
        <motion.div
          initial={{ opacity: 0, y: 50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8 }}
        >
          <h2 className="text-center gradient-text mb-5">Web Development</h2>
          <div className="row">
            {pricingPlans.websites.map((plan, index) => (
              <motion.div
                key={index}
                className="col-lg-4 mb-4"
                initial={{ opacity: 0, y: 50 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5, delay: index * 0.2 }}
              >
                <div className={`pricing-card ${plan.recommended ? 'recommended' : ''}`}>
                  {plan.recommended && <div className="recommended-badge">Most Popular</div>}
                  <h3>{plan.title}</h3>
                  <div className="price">{plan.price}</div>
                  <p className="description">{plan.description}</p>
                  <div className="features-list">
                    {plan.features.map((feature, i) => (
                      <div key={i} className="feature-item">
                        <span className="feature-bullet">✓</span> {feature}
                      </div>
                    ))}
                  </div>
                  <motion.div
                    whileHover={{ scale: 1.05 }}
                    whileTap={{ scale: 0.95 }}
                  >
                    <Link to="/contact" className="cta-button w-100">Get Started</Link>
                  </motion.div>
                </div>
              </motion.div>
            ))}
          </div>
        </motion.div>

        {/* Data Analytics Pricing */}
        <motion.div
          className="mt-5"
          initial={{ opacity: 0, y: 50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8, delay: 0.4 }}
        >
          <h2 className="text-center gradient-text mb-5">Data Analytics</h2>
          <div className="row">
            {pricingPlans.analytics.map((plan, index) => (
              <motion.div
                key={index}
                className="col-lg-4 mb-4"
                initial={{ opacity: 0, y: 50 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5, delay: index * 0.2 }}
              >
                <div className={`pricing-card ${plan.recommended ? 'recommended' : ''}`}>
                  {plan.recommended && <div className="recommended-badge">Most Popular</div>}
                  <h3>{plan.title}</h3>
                  <div className="price">{plan.price}</div>
                  <p className="description">{plan.description}</p>
                  <div className="features-list">
                    {plan.features.map((feature, i) => (
                      <div key={i} className="feature-item">
                        <span className="feature-bullet">✓</span> {feature}
                      </div>
                    ))}
                  </div>
                  <motion.div
                    whileHover={{ scale: 1.05 }}
                    whileTap={{ scale: 0.95 }}
                  >
                    <Link to="/contact" className="cta-button w-100">Get Started</Link>
                  </motion.div>
                </div>
              </motion.div>
            ))}
          </div>
        </motion.div>

        {/* Custom Solutions CTA */}
        <motion.div
          className="custom-solution-cta mt-5 text-center"
          initial={{ opacity: 0, y: 50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8, delay: 0.8 }}
        >
          <h2 className="gradient-text mb-4">Need a Custom Solution?</h2>
          <p className="lead mb-4">Let's discuss your specific requirements and create a tailored package for your business.</p>
          <Link to="/contact" className="cta-button">Get Custom Quote</Link>
        </motion.div>
      </div>
    </div>
  );
};

export default Pricing; 