import PageHeader from '../components/PageHeader';
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';

const Blog = () => {
  const blogPosts = [
    {
      title: 'What is Data Analytics?',
      description: 'A comprehensive guide to understanding data analytics and its impact on modern business.',
      image: 'https://images.unsplash.com/photo-1551288049-bebda4e38f71?auto=format&fit=crop&w=800&q=80',
      category: 'Data Analytics',
      readTime: '5 min read',
      date: 'March 15, 2024',
      link: '/blog/what-is-data-analytics'
    },
    {
      title: 'The True Cost of a Website',
      description: "Breaking down website development costs and what you're really paying for.",
      image: 'https://images.unsplash.com/photo-1460925895917-afdab827c52f?auto=format&fit=crop&w=800&q=80',
      category: 'Web Development',
      readTime: '4 min read',
      date: 'March 10, 2024',
      link: '/blog/website-cost-breakdown'
    },
    {
      title: 'React vs. HTML: Which One is Right for Your Website?',
      description: 'A detailed comparison to help you make the right choice for your project.',
      image: 'https://images.unsplash.com/photo-1633356122544-f134324a6cee?auto=format&fit=crop&w=800&q=80',
      category: 'Web Development',
      readTime: '6 min read',
      date: 'March 5, 2024',
      link: '/blog/react-vs-html'
    },
    {
      title: 'How Machine Learning Can Predict Business Trends',
      description: 'Leveraging AI to stay ahead of market changes and optimize business strategies.',
      image: 'https://images.unsplash.com/photo-1488229297570-58520851e868?auto=format&fit=crop&w=800&q=80',
      category: 'Machine Learning',
      readTime: '7 min read',
      date: 'March 1, 2024',
      link: '/blog/machine-learning-business-trends'
    }
  ];

  return (
    <div>
      <PageHeader
        title="Blog & Insights"
        subtitle="Latest Articles on Web Development, Data Analytics, and Tech Trends"
        backgroundImage="url('https://images.unsplash.com/photo-1486406146926-c627a92ad1ab?auto=format&fit=crop&w=1600&q=80')"
      />

      <div className="container mt-5">
        <motion.div
          initial={{ opacity: 0, y: 50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8 }}
        >
          <div className="row">
            {blogPosts.map((post, index) => (
              <motion.div
                key={index}
                className="col-lg-6 mb-4"
                initial={{ opacity: 0, y: 50 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5, delay: index * 0.2 }}
              >
                <Link to={post.link} className="text-decoration-none">
                  <div className="blog-card">
                    <div 
                      className="blog-image" 
                      style={{ backgroundImage: `url(${post.image})` }}
                    >
                      <div className="blog-category">{post.category}</div>
                    </div>
                    <div className="blog-content">
                      <div className="blog-meta">
                        <span className="date">{post.date}</span>
                        <span className="dot">•</span>
                        <span className="read-time">{post.readTime}</span>
                      </div>
                      <h3>{post.title}</h3>
                      <p className="description">{post.description}</p>
                      <div className="read-more">
                        Read Article
                        <span className="arrow">→</span>
                      </div>
                    </div>
                  </div>
                </Link>
              </motion.div>
            ))}
          </div>
        </motion.div>

        {/* Newsletter Signup */}
        <motion.div
          className="newsletter-section mt-5 text-center"
          initial={{ opacity: 0, y: 50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8, delay: 0.6 }}
        >
          <h2 className="gradient-text mb-4">Stay Updated</h2>
          <p className="lead mb-4">Get the latest articles and insights delivered to your inbox.</p>
          <div className="row justify-content-center">
            <div className="col-md-6">
              <form className="newsletter-form">
                <div className="input-group">
                  <input 
                    type="email" 
                    className="form-control" 
                    placeholder="Enter your email"
                    required
                  />
                  <button type="submit" className="btn cta-button">Subscribe</button>
                </div>
              </form>
            </div>
          </div>
        </motion.div>
      </div>
    </div>
  );
};

export default Blog; 