import PageHeader from '../components/PageHeader';
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';

const Portfolio = () => {
  const projects = [
    {
      title: 'NBA Analytics Platform',
      description: 'Advanced machine learning model for predicting player performance and game outcomes with 85% accuracy.',
      image: 'https://images.unsplash.com/photo-1546519638-68e109498ffc?auto=format&fit=crop&w=800&q=80',
      category: 'Machine Learning',
      tech: ['Python', 'TensorFlow', 'React', 'NBA API'],
      results: 'Improved betting accuracy by 40%',
      link: '/contact'
    },
    {
      title: 'Real-Time Stock Analysis',
      description: 'Automated trading signals platform processing market data in real-time for institutional investors.',
      image: 'https://images.unsplash.com/photo-1611974789855-9c2a0a7236a3?auto=format&fit=crop&w=800&q=80',
      category: 'Data Analytics',
      tech: ['Python', 'Machine Learning', 'AWS', 'React'],
      results: '28% increase in trading efficiency',
      link: '/contact'
    },
    {
      title: 'Personal Finance Tracker',
      description: 'Interactive dashboard for tracking expenses, income, and financial goals with customizable categories and visual reports.',
      image: 'https://images.unsplash.com/photo-1563986768609-322da13575f3?auto=format&fit=crop&w=800&q=80',
      category: 'Web Application',
      tech: ['React', 'Firebase', 'Chart.js', 'Bootstrap'],
      results: 'Helped users reduce spending by 25% on average',
      link: '/contact'
    },
    {
      title: 'Strava Move It Challenge',
      description: 'Fitness challenge platform that integrates with Strava API to track user activities, leaderboards, and achievement badges.',
      image: 'https://images.unsplash.com/photo-1571019613454-1cb2f99b2d8b?auto=format&fit=crop&w=800&q=80',
      category: 'Fitness App',
      tech: ['React', 'Node.js', 'Strava API', 'MongoDB'],
      results: '45% increase in user activity completion rates',
      link: '/contact'
    },
    {
      title: 'E-Commerce Analytics Dashboard',
      description: 'Custom Power BI dashboard providing real-time insights into sales, inventory, and customer behavior.',
      image: 'https://images.unsplash.com/photo-1460925895917-afdab827c52f?auto=format&fit=crop&w=800&q=80',
      category: 'Business Intelligence',
      tech: ['Power BI', 'SQL', 'DAX', 'Python'],
      results: '45% reduction in inventory costs',
      link: '/contact'
    },
    {
      title: 'Predictive Maintenance System',
      description: 'IoT-based system predicting equipment failures before they occur using sensor data analysis.',
      image: 'https://images.unsplash.com/photo-1537884944318-390069bb8665?auto=format&fit=crop&w=800&q=80',
      category: 'Machine Learning',
      tech: ['Python', 'IoT', 'TensorFlow', 'AWS'],
      results: '60% reduction in downtime',
      link: '/contact'
    }
  ];

  const categories = ['All', 'Machine Learning', 'Data Analytics', 'Business Intelligence', 'Web Application', 'Fitness App'];

  return (
    <div>
      <PageHeader
        title="Our Portfolio"
        subtitle="Innovative Solutions for Real Business Challenges"
        backgroundImage="url('https://images.unsplash.com/photo-1486406146926-c627a92ad1ab?auto=format&fit=crop&w=1600&q=80')"
      />

      {/* New Introduction Section */}
      <div className="container mt-5">
        <div className="row justify-content-center mb-5">
          <div className="col-lg-8 text-center">
            <h2 className="gradient-text">Proven Track Record of Success</h2>
            <p className="lead">
              We've helped businesses across various industries transform their operations 
              through custom technology solutions. Each project represents a unique challenge 
              solved and measurable results delivered.
            </p>
          </div>
        </div>

        {/* Stats Section */}
        <div className="row justify-content-center mb-5">
          <div className="col-md-3 mb-4">
            <motion.div 
              className="stat-card text-center"
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.1 }}
            >
              <h3 className="gradient-text">50+</h3>
              <p>Projects Delivered</p>
            </motion.div>
          </div>
          <div className="col-md-3 mb-4">
            <motion.div 
              className="stat-card text-center"
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.2 }}
            >
              <h3 className="gradient-text">99%</h3>
              <p>Client Satisfaction</p>
            </motion.div>
          </div>
          <div className="col-md-3 mb-4">
            <motion.div 
              className="stat-card text-center"
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.3 }}
            >
              <h3 className="gradient-text">35%</h3>
              <p>Avg. Efficiency Gain</p>
            </motion.div>
          </div>
        </div>

        {/* Portfolio Grid */}
        <motion.div
          initial={{ opacity: 0, y: 50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8 }}
        >
          <div className="row">
            {projects.map((project, index) => (
              <motion.div
                key={index}
                className="col-lg-6 mb-4"
                initial={{ opacity: 0, y: 50 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5, delay: index * 0.2 }}
              >
                <div className="portfolio-card">
                  <div 
                    className="portfolio-image" 
                    style={{ backgroundImage: `url(${project.image})` }}
                  >
                    <div className="portfolio-category">{project.category}</div>
                  </div>
                  <div className="portfolio-content">
                    <h3>{project.title}</h3>
                    <p className="description">{project.description}</p>
                    
                    <div className="tech-stack mb-3">
                      {project.tech.map((tech, i) => (
                        <span key={i} className="tech-tag">{tech}</span>
                      ))}
                    </div>

                    <div className="results-section">
                      <div className="results-icon">📈</div>
                      <div className="results-text">{project.results}</div>
                    </div>

                    <motion.div
                      whileHover={{ scale: 1.05 }}
                      whileTap={{ scale: 0.95 }}
                      className="mt-3"
                    >
                      <Link to={project.link} className="cta-button w-100">View Details</Link>
                    </motion.div>
                  </div>
                </div>
              </motion.div>
            ))}
          </div>
        </motion.div>

        {/* New Results Section */}
        <div className="row justify-content-center mb-5">
          <div className="col-lg-12">
            <div className="service-detail-card">
              <h3 className="gradient-text text-center mb-4">Real Business Impact</h3>
              <div className="row">
                <div className="col-md-4 mb-4">
                  <div className="impact-card">
                    <i className="fas fa-chart-line mb-3"></i>
                    <h4>Revenue Growth</h4>
                    <p>Our clients see an average of 40% increase in online revenue after implementing our solutions</p>
                  </div>
                </div>
                <div className="col-md-4 mb-4">
                  <div className="impact-card">
                    <i className="fas fa-tachometer-alt mb-3"></i>
                    <h4>Performance</h4>
                    <p>Websites and applications optimized for 3x faster loading speeds and better user experience</p>
                  </div>
                </div>
                <div className="col-md-4 mb-4">
                  <div className="impact-card">
                    <i className="fas fa-users mb-3"></i>
                    <h4>User Engagement</h4>
                    <p>Average 65% increase in user engagement and conversion rates</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* CTA Section */}
        <div className="row justify-content-center">
          <div className="col-lg-10">
            <div className="cta-section text-center">
              <h2 className="gradient-text mb-4">Ready to Build Something Amazing?</h2>
              <p className="lead mb-4">
                Let's discuss how we can help transform your business with custom technology solutions.
              </p>
              <div className="cta-buttons">
                <Link to="/get-started" className="cta-button me-3">
                  Start Your Project <i className="fas fa-arrow-right ms-2"></i>
                </Link>
                <Link to="/contact" className="cta-button-outline">
                  <i className="fas fa-phone me-2"></i> Schedule a Call
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Portfolio; 