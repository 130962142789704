import { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { db } from '../firebase/config';
import { collection, addDoc, serverTimestamp } from 'firebase/firestore';

const NewsletterPopup = () => {
  const [isVisible, setIsVisible] = useState(false);
  const [email, setEmail] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [error, setError] = useState('');

  useEffect(() => {
    // Show popup after 5 seconds
    const timer = setTimeout(() => {
      const hasSeenPopup = localStorage.getItem('hasSeenPopup');
      if (!hasSeenPopup) {
        setIsVisible(true);
      }
    }, 5000);

    return () => clearTimeout(timer);
  }, []);

  const closePopup = () => {
    setIsVisible(false);
    // Set flag in localStorage so we don't show the popup again
    localStorage.setItem('hasSeenPopup', 'true');
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    // Basic email validation
    if (!email || !/^\S+@\S+\.\S+$/.test(email)) {
      setError('Please enter a valid email address');
      return;
    }
    
    setIsSubmitting(true);
    setError('');
    
    try {
      // Store the email in Firestore
      await addDoc(collection(db, 'newsletter_subscribers'), {
        email,
        subscribed_at: serverTimestamp(),
        source: 'popup'
      });
      
      // Success
      setIsSubmitted(true);
      localStorage.setItem('hasSubscribed', 'true');
      
      // Close popup after 3 seconds
      setTimeout(() => {
        closePopup();
      }, 3000);
      
    } catch (error) {
      console.error('Error adding subscriber: ', error);
      // More specific error message based on the error
      if (error.code === 'permission-denied') {
        setError('Server error: Permission denied. Please try again later.');
      } else if (error.code === 'unavailable') {
        setError('Network error. Please check your connection and try again.');
      } else {
        setError('Something went wrong. Please try again later.');
      }
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <AnimatePresence>
      {isVisible && (
        <motion.div 
          className="newsletter-popup-overlay"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
        >
          <motion.div 
            className="newsletter-popup"
            initial={{ scale: 0.8, opacity: 0 }}
            animate={{ scale: 1, opacity: 1 }}
            exit={{ scale: 0.8, opacity: 0 }}
            transition={{ type: "spring", stiffness: 300 }}
          >
            <button className="close-button" onClick={closePopup}>
              <i className="fas fa-times"></i>
            </button>
            
            {!isSubmitted ? (
              <>
                <h3>Stay Updated</h3>
                <p>Subscribe to our newsletter for the latest insights on web development and data analytics.</p>
                
                <form onSubmit={handleSubmit}>
                  {error && <div className="error-message">{error}</div>}
                  <div className="input-group">
                    <input 
                      type="email" 
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      placeholder="Enter your email"
                      required
                    />
                    <button 
                      type="submit" 
                      className="subscribe-button"
                      disabled={isSubmitting}
                    >
                      {isSubmitting ? 'Subscribing...' : 'Subscribe'}
                    </button>
                  </div>
                </form>
                <small>We respect your privacy. Unsubscribe at any time.</small>
              </>
            ) : (
              <div className="success-message">
                <i className="fas fa-check-circle"></i>
                <h3>Thank You!</h3>
                <p>You've successfully subscribed to our newsletter.</p>
              </div>
            )}
          </motion.div>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default NewsletterPopup; 