import { createBrowserRouter } from 'react-router-dom';
import App from '../App';
import Home from '../pages/Home';
import About from '../pages/About';
import Services from '../pages/Services';
import Portfolio from '../pages/Portfolio';
import Blog from '../pages/Blog';
import Contact from '../pages/Contact';
import GetStarted from '../pages/GetStarted';
import Pricing from '../pages/Pricing';
import Privacy from '../pages/Privacy';
import Terms from '../pages/Terms';
import WhatIsDataAnalytics from '../pages/blog/WhatIsDataAnalytics';
import WebsiteCost from '../pages/blog/WebsiteCost';
import ReactVsHtml from '../pages/blog/ReactVsHtml';
import MachineLearning from '../pages/blog/MachineLearning';

const router = createBrowserRouter([
  {
    path: '/',
    element: <App />,
    children: [
      { path: '/', element: <Home /> },
      { path: '/about', element: <About /> },
      { path: '/services', element: <Services /> },
      { path: '/portfolio', element: <Portfolio /> },
      { path: '/blog', element: <Blog /> },
      { path: '/contact', element: <Contact /> },
      { path: '/get-started', element: <GetStarted /> },
      { path: '/pricing', element: <Pricing /> },
      { path: '/privacy', element: <Privacy /> },
      { path: '/terms', element: <Terms /> },
      { path: '/blog/what-is-data-analytics', element: <WhatIsDataAnalytics /> },
      { path: '/blog/website-cost', element: <WebsiteCost /> },
      { path: '/blog/react-vs-html', element: <ReactVsHtml /> },
      { path: '/blog/machine-learning', element: <MachineLearning /> }
    ]
  }
], {
  future: {
    v7_startTransition: true,
    v7_relativeSplatPath: true
  }
});

export default router; 