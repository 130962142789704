import PageHeader from '../components/PageHeader';
import { motion } from 'framer-motion';

const Privacy = () => {
  return (
    <div>
      <PageHeader
        title="Privacy Policy"
        subtitle="How we handle and protect your information"
        backgroundImage="url('https://images.unsplash.com/photo-1516321318423-f06f85e504b3?auto=format&fit=crop&w=1600&q=80')"
      />
      
      <motion.div 
        className="container mt-5 legal-content"
        initial={{ opacity: 0, y: 50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.8 }}
      >
        <div className="legal-section">
          <h2>Information We Collect</h2>
          <p>We collect information that you provide directly to us, including:</p>
          <ul>
            <li>Name and contact information</li>
            <li>Business information</li>
            <li>Communications and feedback</li>
          </ul>
        </div>

        <div className="legal-section">
          <h2>How We Use Your Information</h2>
          <p>We use the information we collect to:</p>
          <ul>
            <li>Provide and improve our services</li>
            <li>Process your payments</li>
            <li>Send you updates and marketing communications</li>
            <li>Respond to your requests and inquiries</li>
            <li>Comply with legal obligations</li>
          </ul>
        </div>

        <div className="legal-section">
          <h2>Information Sharing</h2>
          <p>We do not sell your personal information. We may share your information with:</p>
          <ul>
            <li>Service providers who assist in our operations</li>
            <li>Professional advisors</li>
            <li>Law enforcement when required by law</li>
          </ul>
        </div>

        <div className="legal-section">
          <h2>Data Security</h2>
          <p>We implement appropriate technical and organizational measures to protect your personal information, including:</p>
          <ul>
            <li>Encryption of sensitive data</li>
            <li>Regular security assessments</li>
            <li>Access controls and authentication</li>
            <li>Secure data storage and transmission</li>
          </ul>
        </div>

        <div className="legal-section">
          <h2>Your Rights</h2>
          <p>You have the right to:</p>
          <ul>
            <li>Access your personal information</li>
            <li>Correct inaccurate information</li>
            <li>Request deletion of your information</li>
            <li>Opt-out of marketing communications</li>
          </ul>
        </div>

        <div className="legal-section">
          <h2>Contact Us</h2>
          <p>If you have questions about our privacy practices, please contact us at:</p>
          <p>Email: management@slydetech.com</p>
          <p>Location: New York, NY</p>
          <p>Last updated: March 1, 2024</p>
        </div>
      </motion.div>
    </div>
  );
};

export default Privacy; 