import { motion } from 'framer-motion';
import PageHeader from '../components/PageHeader';
import { Link } from 'react-router-dom';

const GetStarted = () => {
  const benefits = [
    {
      icon: "fas fa-rocket",
      title: "Accelerate Growth",
      description: "Leverage modern technology to scale your business faster and more efficiently"
    },
    {
      icon: "fas fa-chart-line",
      title: "Data-Driven Decisions",
      description: "Make smarter business decisions with actionable insights from your data"
    },
    {
      icon: "fas fa-code",
      title: "Custom Solutions",
      description: "Get solutions tailored specifically to your business needs and goals"
    }
  ];

  const process = [
    {
      icon: "fas fa-comments",
      title: "1. Discovery Call",
      description: "We start by understanding your business, goals, and challenges",
      points: [
        "Discuss your business objectives",
        "Identify key challenges",
        "Explore potential solutions",
        "Free initial consultation"
      ]
    },
    {
      icon: "fas fa-file-alt",
      title: "2. Custom Proposal",
      description: "Receive a detailed solution plan tailored to your needs",
      points: [
        "Comprehensive solution design",
        "Clear project timeline",
        "Transparent pricing",
        "ROI projections"
      ]
    },
    {
      icon: "fas fa-cogs",
      title: "3. Implementation",
      description: "Expert execution with regular updates and collaboration",
      points: [
        "Agile development process",
        "Regular progress updates",
        "Quality assurance",
        "Continuous feedback"
      ]
    }
  ];

  return (
    <div>
      <PageHeader
        title="Work With Us"
        subtitle="Partner with Experts in Technology Solutions"
        backgroundImage="url('https://images.unsplash.com/photo-1522071820081-009f0129c71c?auto=format&fit=crop&w=1600&q=80')"
      />

      <div className="container mt-5">
        {/* Value Proposition */}
        <div className="row justify-content-center mb-5">
          <div className="col-lg-8 text-center">
            <h2 className="gradient-text mb-4">Transform Your Business with Technology</h2>
            <p className="lead">
              Whether you need a high-performance website, advanced analytics, or AI-driven insights,
              we deliver solutions that drive real business results.
            </p>
          </div>
        </div>

        {/* Benefits */}
        <div className="row mb-5">
          {benefits.map((benefit, index) => (
            <div className="col-md-4 mb-4" key={index}>
              <motion.div 
                className="benefit-card"
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: index * 0.2 }}
                whileHover={{ y: -10 }}
              >
                <div className="benefit-icon">
                  <i className={benefit.icon}></i>
                </div>
                <h3>{benefit.title}</h3>
                <p>{benefit.description}</p>
              </motion.div>
            </div>
          ))}
        </div>

        {/* Process Steps */}
        <div className="row mb-5">
          <div className="col-12">
            <div className="process-section">
              <h2 className="gradient-text text-center mb-5">Our Engagement Process</h2>
              {process.map((step, index) => (
                <motion.div 
                  className="process-step-card"
                  key={index}
                  initial={{ opacity: 0, x: -50 }}
                  animate={{ opacity: 1, x: 0 }}
                  transition={{ delay: index * 0.3 }}
                >
                  <div className="row align-items-center">
                    <div className="col-md-4">
                      <div className="process-step-icon">
                        <i className={step.icon}></i>
                        <h3>{step.title}</h3>
                      </div>
                    </div>
                    <div className="col-md-8">
                      <p className="lead mb-3">{step.description}</p>
                      <ul className="process-points">
                        {step.points.map((point, idx) => (
                          <li key={idx}>
                            <i className="fas fa-check-circle text-success me-2"></i>
                            {point}
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                </motion.div>
              ))}
            </div>
          </div>
        </div>

        {/* Testimonials or Success Stories could go here */}

        {/* CTA Section */}
        <div className="row justify-content-center mb-5">
          <div className="col-lg-10">
            <div className="cta-section text-center">
              <h2 className="gradient-text mb-4">Ready to Get Started?</h2>
              <p className="lead mb-4">
                Schedule a free consultation to discuss your project and explore how we can help.
              </p>
              <div className="cta-buttons">
                <Link to="/contact" className="cta-button me-3">
                  Schedule Consultation <i className="fas fa-arrow-right ms-2"></i>
                </Link>
                <a href="mailto:management@slydetech.com" className="cta-button-outline">
                  <i className="fas fa-envelope me-2"></i> Email Us
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GetStarted; 