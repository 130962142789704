import PageHeader from '../components/PageHeader';
import { motion } from 'framer-motion';

const Terms = () => {
  return (
    <div>
      <PageHeader
        title="Terms of Service"
        subtitle="Please read these terms carefully before using our services"
        backgroundImage="url('https://images.unsplash.com/photo-1450101499163-c8848c66ca85?auto=format&fit=crop&w=1600&q=80')"
      />
      
      <motion.div 
        className="container mt-5 legal-content"
        initial={{ opacity: 0, y: 50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.8 }}
      >
        <div className="legal-section">
          <h2>1. Services</h2>
          <p>SlydeTech provides web development, data analytics, and machine learning solutions. We reserve the right to:</p>
          <ul>
            <li>Modify or discontinue services without notice</li>
            <li>Update pricing and features as needed</li>
          </ul>
        </div>

        <div className="legal-section">
          <h2>2. Contact Information</h2>
          <p>For questions about these terms, contact us at:</p>
          <p>Email: management@slydetech.com</p>
          <p>Location: New York, NY</p>
          <p>Last updated: March 1, 2024</p>
        </div>
      </motion.div>
    </div>
  );
};

export default Terms; 