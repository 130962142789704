import PageHeader from '../../components/PageHeader';
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';

const WebsiteCost = () => {
  return (
    <div>
      <PageHeader
        title="The True Cost of a Website"
        subtitle="What You're Really Paying For in 2024"
        backgroundImage="url('https://images.unsplash.com/photo-1460925895917-afdab827c52f?auto=format&fit=crop&w=1600&q=80')"
      />
      
      <motion.div 
        className="container mt-5 blog-post"
        initial={{ opacity: 0, y: 50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.8 }}
      >
        <div className="blog-content">
          <p className="lead">Ever wonder why website prices vary so much? You'll see options from low-cost DIY templates to high-end custom builds—but what's the real difference?</p>
          
          <p>Many business owners assume a website is just a few pages of text and images, but the reality is much deeper. A professional website is a long-term investment that determines how customers perceive your brand, how they interact with your business, and—most importantly—how much revenue it can generate.</p>

          <h2>What Are You Actually Paying For?</h2>
          <p>A successful website isn't just a digital brochure—it's an engine that drives business growth. Here's what goes into the cost:</p>

          <h3>1️⃣ Strategy & Planning 🚀</h3>
          <div className="example-box">
            <p>Before a single line of code is written, time is spent on:</p>
            <ul>
              <li>Understanding your business goals & target audience</li>
              <li>Mapping out user experience (UX) flows to improve conversions</li>
              <li>Planning SEO structure to ensure Google ranks your site</li>
            </ul>
            <div className="insight-box">
              <p>🔹 <strong>Why this impacts cost:</strong> A custom-tailored strategy takes research and expertise, leading to higher upfront costs compared to a generic template.</p>
            </div>
          </div>

          <h3>2️⃣ Design & Branding 🎨</h3>
          <div className="example-box">
            <p>Your website needs to do more than just exist—it should captivate and convert visitors:</p>
            <ul>
              <li>Custom UI/UX design to match your brand identity</li>
              <li>Mobile responsiveness to ensure seamless experience on all devices</li>
              <li>Visual storytelling—engaging layouts that guide users toward action</li>
            </ul>
            <div className="insight-box">
              <p>🔹 <strong>Why this impacts cost:</strong> Unique, high-quality designs require professional expertise, while template-based designs are more affordable but less customized.</p>
            </div>
          </div>

          {/* Continue with Development & Functionality, Hosting & Maintenance sections... */}

          <h2>Why Do Website Costs Fluctuate? 💰</h2>
          <div className="example-box">
            <h3>✅ Static vs. Dynamic Content</h3>
            <ul>
              <li>Basic static websites (informational pages, blogs) are straightforward and cheaper</li>
              <li>Dynamic sites (e-commerce, dashboards, booking systems) require databases, user authentication, and backend logic—driving up costs</li>
            </ul>
          </div>

          <div className="tip-box">
            <h3>Hidden Costs to Consider 🤔</h3>
            <ul>
              <li>💡 Domain & Hosting – Ranges from budget-friendly shared hosting to premium cloud hosting</li>
              <li>💡 SEO & Content – Websites without optimized content won't rank on Google</li>
              <li>💡 Third-Party Integrations – CRM, email marketing tools, payment gateways, etc</li>
              <li>💡 Ongoing Maintenance – Security updates, performance monitoring, and new feature implementations</li>
            </ul>
          </div>

          <h2>Final Thoughts: A Website is an Investment, Not an Expense</h2>
          <p>Your website is the face of your business. It can either be a powerful tool that brings in revenue or just another online page that people forget. Investing in a well-built website isn't about just getting online—it's about creating a growth-driven digital experience that helps your business thrive.</p>

          <div className="cta-section mt-5">
            <h3>Ready to Invest in Your Digital Success?</h3>
            <p>At SlydeTech, we don't just build websites—we create solutions tailored to your business needs and goals.</p>
            <Link to="/contact" className="cta-button">Get Your Website Quote</Link>
          </div>
        </div>
      </motion.div>
    </div>
  );
};

export default WebsiteCost; 