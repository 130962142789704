import { useState } from 'react';
import { motion } from 'framer-motion';
import PageHeader from '../components/PageHeader';
import { db } from '../firebase/config';
import { collection, addDoc, serverTimestamp } from 'firebase/firestore';

const Contact = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    service: 'Web Development',
    message: ''
  });

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitStatus, setSubmitStatus] = useState({ type: '', message: '' });

  const services = [
    'Web Development',
    'Data Analytics',
    'Machine Learning',
    'Business Intelligence',
    'Custom Solution'
  ];

  const contactInfo = [
    {
      title: 'Email',
      info: 'james@slydetech.com',
      icon: '📧',
      link: 'mailto:james@slydetech.com'
    },
    {
      title: 'Location',
      info: 'New York, NY',
      icon: '📍',
      link: null
    },
    {
      title: 'Hours',
      info: 'Mon - Fri: 9am - 6pm EST',
      icon: '🕒',
      link: null
    }
  ];

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    setSubmitStatus({ type: '', message: '' });

    try {
      // Store the contact form submission in Firestore
      await addDoc(collection(db, 'contact_submissions'), {
        ...formData,
        submitted_at: serverTimestamp()
      });
      
      // Success
      setSubmitStatus({
        type: 'success',
        message: 'Your message has been sent! We\'ll get back to you soon.'
      });
      
      // Reset form
      setFormData({
        name: '',
        email: '',
        phone: '',
        service: 'Web Development',
        message: ''
      });
      
    } catch (error) {
      console.error('Error submitting form: ', error);
      // More specific error message based on the error
      if (error.code === 'permission-denied') {
        setSubmitStatus({
          type: 'error',
          message: 'Server error: Permission denied. Please email us directly at james@slydetech.com.'
        });
      } else if (error.code === 'unavailable') {
        setSubmitStatus({
          type: 'error',
          message: 'Network error. Please check your connection and try again.'
        });
      } else {
        setSubmitStatus({
          type: 'error',
          message: 'Something went wrong. Please try again or email us directly at james@slydetech.com.'
        });
      }
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div>
      <PageHeader
        title="Contact Us"
        subtitle="Let's discuss your next project"
        backgroundImage="url('https://images.unsplash.com/photo-1486406146926-c627a92ad1ab?auto=format&fit=crop&w=1600&q=80')"
      />
      
      <motion.div 
        className="container mt-5"
        initial={{ opacity: 0, y: 50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.8 }}
      >
        <div className="row">
          <div className="col-lg-5 mb-4">
            <div className="contact-info">
              <h2 className="gradient-text mb-4">Get in Touch</h2>
              <p className="lead mb-4">
                Ready to transform your business with innovative tech solutions? 
                We're here to help bring your vision to life.
              </p>
              
              <div className="contact-info-card mb-4">
                <div className="info-icon">
                  <i className="fas fa-envelope"></i>
                </div>
                <div className="info-content">
                  <h3>Email Us</h3>
                  <a href="mailto:management@slydetech.com">management@slydetech.com</a>
                </div>
              </div>

              <div className="contact-info-card mb-4">
                <div className="info-icon">
                  <i className="fas fa-map-marker-alt"></i>
                </div>
                <div className="info-content">
                  <h3>Location</h3>
                  <p>New York, NY</p>
                </div>
              </div>

              <div className="contact-info-card">
                <div className="info-icon">
                  <i className="fas fa-clock"></i>
                </div>
                <div className="info-content">
                  <h3>Availability</h3>
                  <p>Available for projects and consultations as needed</p>
                </div>
              </div>
            </div>
          </div>

          <div className="col-lg-7">
            <div className="contact-form">
              <h2 className="gradient-text mb-4">Send Us a Message</h2>
              <form onSubmit={handleSubmit}>
                <div className="row">
                  <div className="col-md-6 mb-3">
                    <input
                      type="text"
                      name="name"
                      placeholder="Your Name"
                      value={formData.name}
                      onChange={handleChange}
                      required
                      className="form-control"
                    />
                  </div>
                  <div className="col-md-6 mb-3">
                    <input
                      type="email"
                      name="email"
                      placeholder="Your Email"
                      value={formData.email}
                      onChange={handleChange}
                      required
                      className="form-control"
                    />
                  </div>
                </div>
                <div className="row mb-3">
                  <div className="col-md-6 mb-3">
                    <input
                      type="tel"
                      name="phone"
                      placeholder="Phone Number (Optional)"
                      value={formData.phone}
                      onChange={handleChange}
                      className="form-control"
                    />
                  </div>
                  <div className="col-md-6 mb-3">
                    <select
                      name="service"
                      value={formData.service}
                      onChange={handleChange}
                      className="form-control"
                    >
                      {services.map((service, index) => (
                        <option key={index} value={service}>
                          {service}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                <div className="mb-4">
                  <textarea
                    name="message"
                    placeholder="Your Message"
                    rows="5"
                    value={formData.message}
                    onChange={handleChange}
                    required
                    className="form-control"
                  />
                </div>
                {submitStatus.message && (
                  <div className={`alert ${submitStatus.type === 'success' ? 'alert-success' : 'alert-danger'} mb-3`}>
                    {submitStatus.message}
                  </div>
                )}
                <motion.button
                  type="submit"
                  className="submit-button"
                  whileHover={{ scale: 1.02 }}
                  whileTap={{ scale: 0.98 }}
                  disabled={isSubmitting}
                >
                  {isSubmitting ? 'Sending...' : 'Send Message'}
                </motion.button>
              </form>
            </div>
          </div>
        </div>
      </motion.div>
    </div>
  );
};

export default Contact; 