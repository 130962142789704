import { Link } from 'react-router-dom';
import { useEffect } from 'react';

const Footer = () => {
  // Function to scroll to top when navigating to a new page
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };

  // This will scroll to top whenever the component mounts (page changes)
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <footer className="footer-modern">
      <div className="container">
        <div className="row">
          <div className="col-lg-4 col-md-6 mb-4">
            <div className="footer-content">
              <h5 className="mb-3">SlydeTech</h5>
              <p className="footer-description">
                Transforming businesses with cutting-edge technology solutions and data-driven insights.
              </p>
              <div className="footer-contact">
                <a href="mailto:management@slydetech.com" className="contact-link">
                  <i className="fas fa-envelope"></i> management@slydetech.com
                </a>
                
                <div className="location">
                  <i className="fas fa-map-marker-alt"></i> New York, NY
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 mb-4">
            <div className="footer-content">
              <h5 className="mb-3">Company</h5>
              <div className="footer-links">
                <Link to="/about" onClick={scrollToTop}>About Us</Link>
                <Link to="/services" onClick={scrollToTop}>Services</Link>
                <Link to="/portfolio" onClick={scrollToTop}>Portfolio</Link>
                <Link to="/blog" onClick={scrollToTop}>Blog</Link>
                <Link to="/contact" onClick={scrollToTop}>Contact</Link>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 mb-4">
            <div className="footer-content">
              <h5 className="mb-3">Services</h5>
              <div className="footer-links">
                <Link to="/services#web-development" onClick={scrollToTop}>Web Development</Link>
                <Link to="/services#data-analytics" onClick={scrollToTop}>Data Analytics</Link>
                <Link to="/services#machine-learning" onClick={scrollToTop}>Machine Learning</Link>
                <Link to="/services#consulting" onClick={scrollToTop}>Consulting</Link>
                <Link to="/services#cloud-solutions" onClick={scrollToTop}>Cloud Solutions</Link>
              </div>
            </div>
          </div>
        </div>
        <div className="footer-bottom">
          <div className="copyright-wrapper">
            <div className="copyright">
              &copy; {new Date().getFullYear()} SlydeTech. All rights reserved.
            </div>
            <div className="legal-links">
              <Link to="/privacy" onClick={scrollToTop}>Privacy Policy</Link>
              <Link to="/terms" onClick={scrollToTop}>Terms of Service</Link>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer; 