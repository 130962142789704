import PageHeader from '../components/PageHeader';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import { useEffect, useState } from 'react';

const Home = () => {
  const [displayText, setDisplayText] = useState("");
  const [isTypingComplete, setIsTypingComplete] = useState(false);
  
  useEffect(() => {
    const text = "Transform Your Business with Data";
    let i = 0;
    
    const typeWriter = () => {
      if (i < text.length) {
        setDisplayText(text.substring(0, i+1));
        i++;
        setTimeout(typeWriter, 80);
      } else {
        setIsTypingComplete(true);
      }
    };
    
    setTimeout(() => {
      typeWriter();
    }, 500);
  }, []);

  // Text animation variants for the subtitle
  const subtitleVariants = {
    hidden: { opacity: 0 },
    visible: { 
      opacity: 1,
      transition: { 
        delay: 2.5,
        duration: 1 
      }
    }
  };

  const services = [
    {
      title: "Web Development",
      description: "Modern, responsive websites built with React",
      icon: "💻"
    },
    {
      title: "Data Analytics",
      description: "Turn your data into actionable insights",
      icon: "📊"
    },
    {
      title: "Machine Learning",
      description: "AI-powered solutions for business growth",
      icon: "🤖"
    }
  ];

  const featuredProjects = [
    {
      title: "NBA Analytics Platform",
      description: "AI-powered predictions for player performance and game outcomes",
      image: "https://images.unsplash.com/photo-1546519638-68e109498ffc?auto=format&fit=crop&w=800&q=80",
      tech: ["React", "Python", "Machine Learning"]
    },
    {
      title: "Real-Time Stock Analysis",
      description: "Live market data analysis with automated trading signals",
      image: "https://images.unsplash.com/photo-1611974789855-9c2a0a7236a3?auto=format&fit=crop&w=800&q=80",
      tech: ["Data Analytics", "AI", "Power BI"]
    }
  ];

  const testimonials = [
    {
      name: "Sarah Johnson",
      role: "CEO, TechStart Inc",
      content: "SlydeTech transformed our business with their data-driven solutions. Our efficiency improved by 150%!",
      image: "https://images.unsplash.com/photo-1494790108377-be9c29b29330?auto=format&fit=crop&w=150&q=80"
    },
    {
      name: "Michael Chen",
      role: "Director of Analytics, SportsBet",
      content: "The NBA betting model they built for us has been incredibly accurate. A game-changer for our operations.",
      image: "https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?auto=format&fit=crop&w=150&q=80"
    }
  ];

  return (
    <div>
      <div className="modern-header">
        <div className="container">
          <div className="header-content">
            <motion.h1 
              className="gradient-text main-title"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 0.5 }}
            >
              {displayText}
              <span className={`cursor ${isTypingComplete ? 'blink' : ''}`}></span>
            </motion.h1>
            
            <motion.p 
              className="subtitle"
              variants={subtitleVariants}
              initial="hidden"
              animate="visible"
            >
              Custom Web Development & Data Analytics Solutions
            </motion.p>
            
            <motion.div 
              className="header-buttons"
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 3, duration: 0.8 }}
            >
              <Link to="/contact" className="cta-button">Get Started</Link>
              <Link to="/portfolio" className="cta-button-secondary">View Portfolio</Link>
            </motion.div>
          </div>
        </div>
      </div>
      
      <div className="container mt-5">
        <motion.div 
          className="row align-items-center hero-section"
          initial={{ opacity: 0, y: 50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8 }}
        >
          <motion.div 
            className="col-lg-6"
            whileHover={{ scale: 1.02 }}
            transition={{ type: "spring", stiffness: 300 }}
          >
            <h2 className="gradient-text">Elevate Your Digital Presence</h2>
            <p className="lead mb-4">
              We combine cutting-edge web development with powerful data analytics 
              to help businesses thrive in the digital age.
            </p>
            <div className="d-flex gap-3">
              <motion.div
                whileHover={{ scale: 1.1 }}
                whileTap={{ scale: 0.9 }}
              >
                <Link to="/contact" className="cta-button">Get Started</Link>
              </motion.div>
              <motion.div
                whileHover={{ scale: 1.1 }}
                whileTap={{ scale: 0.9 }}
              >
                <Link to="/portfolio" className="cta-button-secondary">View Portfolio</Link>
              </motion.div>
            </div>
          </motion.div>
          <motion.div 
            className="col-lg-6"
            initial={{ opacity: 0, x: 100 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.8, delay: 0.2 }}
          >
            <img 
              src="https://images.unsplash.com/photo-1551288049-bebda4e38f71?auto=format&fit=crop&w=800&q=80" 
              alt="Data Analytics Dashboard" 
              className="img-fluid rounded shadow-lg"
            />
          </motion.div>
        </motion.div>

        {/* Services Section */}
        <motion.div 
          className="services-section mt-5"
          initial={{ opacity: 0, y: 50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8, delay: 0.4 }}
        >
          <h2 className="text-center gradient-text mb-5">Our Services</h2>
          <div className="row">
            {services.map((service, index) => (
              <motion.div 
                key={index}
                className="col-md-4 mb-4"
                whileHover={{ y: -10 }}
                transition={{ type: "spring", stiffness: 300 }}
              >
                <div className="service-card text-center">
                  <div className="service-icon">{service.icon}</div>
                  <h3>{service.title}</h3>
                  <p>{service.description}</p>
                  <Link to="/services" className="service-link">
                    Learn More <i className="fas fa-arrow-right ms-1"></i>
                  </Link>
                </div>
              </motion.div>
            ))}
          </div>
        </motion.div>

        {/* Featured Projects */}
        <motion.div 
          className="featured-projects mt-5"
          initial={{ opacity: 0, y: 50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8, delay: 0.6 }}
        >
          <h2 className="text-center gradient-text mb-5">Featured Projects</h2>
          <div className="row">
            {featuredProjects.map((project, index) => (
              <motion.div 
                key={index}
                className="col-md-6 mb-4"
                whileHover={{ y: -10 }}
                transition={{ type: "spring", stiffness: 300 }}
              >
                <div className="project-card">
                  <div className="project-image">
                    <img src={project.image} alt={project.title} />
                  </div>
                  <div className="project-content">
                    <h3>{project.title}</h3>
                    <p>{project.description}</p>
                    <div className="tech-stack">
                      {project.tech.map((tech, i) => (
                        <span key={i} className="tech-tag">{tech}</span>
                      ))}
                    </div>
                  </div>
                </div>
              </motion.div>
            ))}
          </div>
        </motion.div>

        {/* Testimonials */}
        <motion.div 
          className="testimonials-section mt-5"
          initial={{ opacity: 0, y: 50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8, delay: 1 }}
        >
          <h2 className="text-center gradient-text mb-5">What Our Clients Say</h2>
          <div className="row">
            {testimonials.map((testimonial, index) => (
              <motion.div 
                key={index}
                className="col-md-6 mb-4"
                whileHover={{ y: -10 }}
                transition={{ type: "spring", stiffness: 300 }}
              >
                <div className="testimonial-card">
                  <div className="testimonial-content">
                    <p>"{testimonial.content}"</p>
                    <div className="testimonial-author">
                      <img src={testimonial.image} alt={testimonial.name} className="author-image" />
                      <div className="author-info">
                        <h4>{testimonial.name}</h4>
                        <p>{testimonial.role}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </motion.div>
            ))}
          </div>
        </motion.div>

        {/* Data Benefits Section */}
        <motion.div 
          className="data-benefits-section particles-container"
          initial={{ opacity: 0, y: 50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8, delay: 1.4 }}
        >
          <div className="particles"></div>
          <h2 className="text-center gradient-text mb-5">Unlock Your Business Potential with Data</h2>
          
          <div className="row">
            <div className="col-lg-6 mb-4">
              <div className="benefit-card insight-card">
                <div className="benefit-icon">
                  <i className="fas fa-lightbulb"></i>
                </div>
                <h3>Discover Hidden Opportunities</h3>
                <p>Most businesses collect data but never analyze it. We help you uncover valuable insights that reveal new revenue streams and untapped markets.</p>
                <div className="benefit-stat">
                  <span className="stat-number">73%</span>
                  <span className="stat-text">of business data goes unused</span>
                </div>
              </div>
            </div>
            
            <div className="col-lg-6 mb-4">
              <div className="benefit-card prediction-card">
                <div className="benefit-icon">
                  <i className="fas fa-chart-line"></i>
                </div>
                <h3>Predict Future Trends</h3>
                <p>Stop reacting and start anticipating. Our predictive models help you forecast customer behavior, market shifts, and business performance.</p>
                <div className="benefit-stat">
                  <span className="stat-number">2.5x</span>
                  <span className="stat-text">faster response to market changes</span>
                </div>
              </div>
            </div>
            
            <div className="col-lg-6 mb-4">
              <div className="benefit-card optimization-card">
                <div className="benefit-icon">
                  <i className="fas fa-cogs"></i>
                </div>
                <h3>Optimize Your Operations</h3>
                <p>Eliminate inefficiencies by letting data guide your decisions. From inventory management to resource allocation, we help you maximize productivity.</p>
                <div className="benefit-stat">
                  <span className="stat-number">40%</span>
                  <span className="stat-text">average cost reduction</span>
                </div>
              </div>
            </div>
            
            <div className="col-lg-6 mb-4">
              <div className="benefit-card customer-card">
                <div className="benefit-icon">
                  <i className="fas fa-users"></i>
                </div>
                <h3>Understand Your Customers</h3>
                <p>Gain deep insights into customer preferences and behaviors. Create personalized experiences that drive loyalty and increase lifetime value.</p>
                <div className="benefit-stat">
                  <span className="stat-number">65%</span>
                  <span className="stat-text">improvement in customer retention</span>
                </div>
              </div>
            </div>
          </div>
          
          <div className="text-center mt-4">
            <Link to="/services#data-analytics" className="cta-button">
              Explore Data Solutions <i className="fas fa-arrow-right ms-2"></i>
            </Link>
          </div>
        </motion.div>
      </div>
    </div>
  );
};

export default Home; 